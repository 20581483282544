import logo from '../src/Assets/Logo.svg';
import apple from '../src/Assets/Apple.svg';
import google from '../src/Assets/google.svg';
import back from '../src/Assets/Black.png';
import path90 from '../src/Assets/path90.svg';
import iphone1 from '../src/Assets/iPhone1.svg';
import iphone2 from '../src/Assets/iPhone2.png';
import iphone3 from '../src/Assets/Iphone31.svg';
import soldier from '../src/Assets/soldier.svg';
import iphone5 from '../src/Assets/iPhone5.png';
import iphone6 from '../src/Assets/iPhone6.png';
import logo2 from '../src/Assets/Logo2.svg';
import back2 from '../src/Assets/back2.png';
import googlebtn from '../src/Assets/google-btn.svg';
import applebtn from '../src/Assets/apple-btn.svg';
import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, List, ListItem, Link } from '@mui/material';



import './App.css';

function App() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);


  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname); // Update path on change
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);




  return (
    <div className="App">
      {
        currentPath == "/" &&
        <body className='wrapper'>
          <header>
            <div className="Logo">
              <img src={logo} alt="ACFT Logo" />
            </div>
            {/* <div className="button-green">
              <button>Download App</button>
            </div> */}
            <div></div>
          </header>
          <main>
            <div className='top-section'>
              <div className='top-section-left'>
                <div className='top-section-left-up'>
                  <div className='top-section-left-title'>
                    Ace Your ACFT with Precision
                  </div>
                  <div className='top-section-left-content'>
                    <div>Download the Ultimate ACFT Calculator</div>
                    <p>Track, Train, and Excel in Your Army Combat Fitness Test</p>
                  </div>
                </div>
                <div className='top-section-left-buttons'>
                  <a id='a1' name='first-apple-a' className='apple-btn' href='https://apps.apple.com/rs/app/acft-elite/id6670795718'>
                    <img src={applebtn} alt='Button for Apple Store' />
                  </a>
                  <a id='a2' name='first-google-a' className='google-btn' href='https://play.google.com/store/apps/details?id=com.vemdigital.eliteacft'>
                    <img src={googlebtn} alt='Button for Google Store' />
                  </a>
                </div>

              </div>
              <div className='top-section-right'>
                {/*<img src={back} alt='Image of Iphone with ACFT App'/>*/}
              </div>
            </div>
            <div className='container'>
              <div className='element-one'>
                <div className='element-one-left'>
                  <img src={iphone1} alt='Google logo' />
                </div>
                <div className='element-one-right'>
                  <div className='element-one-right-up'>
                    <div className='element-one-right-green'>
                      ACFT Standards
                    </div>
                    <div className='element-one-right-main'>
                      Up-to-date ACFT score chart.
                    </div>
                  </div>
                  <div className='element-one-right-gray'>
                    Stay informed with the latest ACFT score chart. Our app provides up to date scoring requirements to help you meet and exceed your fitness goals
                  </div>
                </div>
              </div>
              <div className='element-two'>
                <div className='element-one-left'>
                  <img src={iphone2} alt='Google logo' />
                </div>
                <div className='element-one-right'>
                  <div className='element-one-right-up'>
                    <div className='element-one-right-green'>
                      Progress Tracking
                    </div>
                    <div className='element-one-right-main'>
                      Monitor improvements across all ACFT events.
                    </div>
                  </div>
                  <div className='element-one-right-gray'>
                    Track your progress in every ACFT event. Our app helps you monitor improvements, set goals, and stay motivated on your fitness journey
                  </div>
                </div>
              </div>
              <div className='element-three'>
                <div className='element-one-left'>
                  <img src={iphone3} alt='Google logo' />
                </div>
                <div className='element-one-right'>
                  <div className='element-one-right-up'>
                    <div className='element-one-right-green'>
                      User-Friendly Interface
                    </div>
                    <div className='element-one-right-main'>
                      Easy to use, even in the field.
                    </div>
                  </div>
                  <div className='element-one-right-gray'>
                    Navigate with ease using our intuitive interface. Designed for simplicity and efficiency, our app is user-friendly whether you're at home or in the field
                  </div>
                </div>
              </div>
              <div className='element-four'>
                <div className='card'>
                  <div>
                    <img src={iphone5} alt='Google logo' />
                  </div>
                  <div className='card-text'>
                    <div className='card-text-up'>
                      <div className='card-text-up-green'>
                        Weight & Nutrition
                      </div>
                      <div className='card-text-up-main'>
                        Create a custom macro-nutrient diet plan and track your progress.
                      </div>
                    </div>
                    <div className='card-text-down'>
                      Tailor your nutrition with personalized macro plans. Track your dietary progress and stay on course to meet your fitness and health goals
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-img'>
                    <img src={iphone6} alt='Google logo' />
                  </div>
                  <div className='card-text'>
                    <div className='card-text-up'>
                      <div className='card-text-up-green'>
                        Stopwatch Integration
                      </div>
                      <div className='card-text-up-main'>
                        Seamlessly integrate your stopwatch results into your overall progress tracker.
                      </div>
                    </div>
                    <div className='card-text-down'>
                      Sync your stopwatch results effortlessly. Our app integrates your timed activities into your progress tracker, providing a comprehensive view of your performance
                    </div>
                  </div>
                </div>
              </div>
              <div className='element-five'>
                <div className='element-one-left'>
                  <img src={soldier} alt='Google logo' />
                </div>
                <div className='element-one-right'>
                  <div className='element-one-right-up'>
                    <div className='element-one-right-main'>
                      A portion of every purchase is donated to the wounded warrior project
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className='bottom-section'>
              <div className='bottom-section-img'>
              </div>
              <div className='bottom-section-content'>
                <div className='bottom-section-content-up'>
                  <div className='bottom-section-content-logo'>
                    <img src={logo2} alt='Google logo' />
                  </div>
                  <div className='bottom-section-content-main'>
                    Download the Ultimate ACFT Calculator
                  </div>
                </div>
                <div className='bottom-section-content-button'>
                  <a className='apple-btn' href='https://apps.apple.com/rs/app/acft-elite/id6670795718'>
                    <img src={applebtn} alt='Button for Apple Store' />
                  </a>
                  <a className='google-btn' href='https://play.google.com/store/apps/details?id=com.vemdigital.eliteacft'>
                    <img src={googlebtn} alt='Button for Google Store' />
                  </a>
                </div>
                <div className='footer'>
                  <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; ACFT</p>
                  <p className='footer-element element' onClick={() => { window.location.href = '/privacy'; }}>Privacy Policy</p>
                  <p className='footer-element element' onClick={() => { window.location.href = '/support'; }}>Support</p>
                  <p className='footer-element element' onClick={() => { window.location.href = '/sources'; }}>Sources</p>
                </div>
              </div>
            </div>
          </main>
        </body>
      }

      {
        currentPath == "/privacy" &&
        <Container maxWidth="md" className='textScreens'>
          <Paper style={{ padding: '30px', backgroundColor: '#2a3126', color: 'white' }}>
            <Typography variant="h4" gutterBottom>
              Privacy Policy for ACFT Elite
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Effective Date: 11/15/2024
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Last Updated: 11/15/2024
            </Typography>
            <Typography variant="body1" paragraph>
              This Privacy Policy (“Policy”) governs the collection, use, and disclosure of personal information by ACFT Elite (“we,” “us,” or “our”) in connection with your use of the ACFT Elite mobile application (the “App”). By accessing or using the App, you acknowledge that you have read, understood, and agreed to the terms set forth in this Policy. If you do not agree with this Policy, please refrain from using the App.
            </Typography>

            <Typography variant="h6" gutterBottom>
              1. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              ACFT Elite collects the following categories of information:
            </Typography>
            <List>
              <ListItem><Typography variant="body1">- Required Personal Information:</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Full name</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Email address</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Sex</Typography></ListItem>
              <ListItem><Typography variant="body1">- Optional Information (User-Provided):</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Exercise results (e.g., performance metrics for specific exercises)</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Height</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Weight</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Waist circumference</Typography></ListItem>
              <ListItem><Typography variant="body1">  - Neck circumference</Typography></ListItem>
            </List>
            <Typography variant="body1" paragraph>
              This information is collected directly from you when you input data into the App.
            </Typography>

            <Typography variant="h6" gutterBottom>
              2. Purpose of Data Collection
            </Typography>
            <Typography variant="body1" paragraph>
              We process your personal information for legitimate business purposes, including but not limited to:
            </Typography>
            <List>
              <ListItem><Typography variant="body1">- Provision of Services: To operate, maintain, and enhance the App’s functionality and user experience.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Performance Analytics: To track and analyze exercise and body composition data for your personal fitness goals.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Communication: To send administrative updates, respond to inquiries, or provide technical support.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Compliance and Legal Obligations: To comply with applicable laws, regulations, or legal processes.</Typography></ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              3. Legal Basis for Data Processing
            </Typography>
            <Typography variant="body1" paragraph>
              We collect and process personal information based on one or more of the following legal grounds:
            </Typography>
            <List>
              <ListItem><Typography variant="body1">- Consent: By providing personal information, you consent to its processing in accordance with this Policy.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Contractual Necessity: Processing may be required to fulfill the services provided through the App.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Legitimate Interests: To improve App functionality and user experience, consistent with your rights and expectations.</Typography></ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
              4. Data Sharing and Disclosure
            </Typography>
            <Typography variant="body1" paragraph>
              ACFT Elite does not sell, lease, or trade your personal information. However, your data may be shared under the following limited circumstances:
            </Typography>
            <List>
              <ListItem><Typography variant="body1">- Authorized Service Providers: We may engage third-party vendors or contractors to support App operations, subject to strict confidentiality obligations.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Legal Compliance: Personal information may be disclosed to comply with legal obligations, enforce terms of use, or protect our rights.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Aggregate Data: We may share anonymized, aggregated data that does not identify you, for research or analytics purposes.</Typography></ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              5. Data Retention and Security
            </Typography>
            <Typography variant="body1" paragraph>
              We retain personal information only as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required by law. To protect your information, we implement robust administrative, technical, and physical safeguards. Despite our efforts, no method of electronic storage or transmission is entirely secure, and we cannot guarantee absolute security.
            </Typography>

            <Typography variant="h6" gutterBottom>
              6. Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You have certain rights with respect to your personal information, including but not limited to:
            </Typography>
            <List>
              <ListItem><Typography variant="body1">- Access and Portability: The right to request access to your data and obtain a copy.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Correction: The right to request correction of inaccurate or incomplete information.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Erasure: The right to request deletion of your personal data, subject to legal or contractual obligations.</Typography></ListItem>
              <ListItem><Typography variant="body1">- Data Restriction: The right to limit processing of your data under certain conditions.</Typography></ListItem>
            </List>
            <Typography variant="body1" paragraph>
              To exercise these rights, contact us at info@acftelite.com.
            </Typography>

            <Typography variant="h6" gutterBottom>
              7. International Data Transfers
            </Typography>
            <Typography variant="body1" paragraph>
              If you access the App outside the United States, please note that your data may be transferred to and processed in the United States. By using the App, you consent to such transfers.
            </Typography>

            <Typography variant="h6" gutterBottom>
              8. Children’s Privacy
            </Typography>
            <Typography variant="body1" paragraph>
              The App is not intended for individuals under the age of 13, and we do not knowingly collect information from children. If we discover that a minor’s data has been collected without parental consent, we will promptly delete it.
            </Typography>

            <Typography variant="h6" gutterBottom>
              9. Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right to amend this Policy at our sole discretion. Any material changes will be communicated through the App or other appropriate channels. Your continued use of the App after such changes constitutes your acceptance of the updated Policy.
            </Typography>

            <Typography variant="h6" gutterBottom>
              10. Contact Information
            </Typography>
            <Typography variant="body1" paragraph>
              If you have questions, concerns, or requests regarding this Policy, please contact us at: info@acftelite.com
            </Typography>
            <Typography variant="body1" paragraph>
              This Policy is governed by the laws of the State of Pennsylvania, without regard to its conflict of law principles.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              © 2024 ACFT Elite. All Rights Reserved.
            </Typography>
          </Paper>
          <div className='footer'>
            <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; ACFT</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/privacy'; }}>Privacy Policy</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/support'; }}>Support</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/sources'; }}>Sources</p>
          </div>
        </Container>
      }

      {
        currentPath == "/sources" &&
        <Container maxWidth="md" className='textScreens'>
          <Paper style={{ padding: '30px', backgroundColor: '#2a3126', color: 'white' }}>
            <Typography variant="h4" gutterBottom>
              Nutrition Sources & Citations
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Body fat calculator:</strong> <Link href="https://www.armyresilience.army.mil/abcp/BodyFatCalculator.html">link</Link>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Macro nutrition calculator:</strong> <Link href="https://www.niddk.nih.gov/bwp">link</Link>
            </Typography>
          </Paper>
          <div className='footer'>
            <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; ACFT</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/privacy'; }}>Privacy Policy</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/support'; }}>Support</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/sources'; }}>Sources</p>
          </div>
        </Container>
      }


      {
        currentPath == "/support" &&
        <Container maxWidth="md" className='textScreens'>
          <Paper style={{ padding: '30px', backgroundColor: '#2a3126', color: 'white' }}>
            <Typography variant="h4" gutterBottom>
              Support for ACFT Elite
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Address:</strong> 30 S 15th St Philadelphia, PA 19102
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Phone:</strong> <Link href="tel:+15702907496">+1-570-290-7496</Link>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Email:</strong> <Link href="mailto:info@EASYMP3.com">info@acftelite.com</Link>
            </Typography>
          </Paper>
          <div className='footer'>
            <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; ACFT</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/privacy'; }}>Privacy Policy</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/support'; }}>Support</p>
            <p className='footer-element element' onClick={() => { window.location.href = '/sources'; }}>Sources</p>
          </div>
        </Container>
      }

    </div>

  );
}

export default App;
